import React from 'react';
import currencyFormatter from 'currency-formatter';
import moment from 'moment';
import { intervalToDuration, formatDuration } from 'date-fns';
import {
  LOGIN_TYPE_PHONE,
  LOGIN_TYPE_EMAIL,
  UUID_REGEX_VALIDATION,
  ROLE_AUTHORITIES,
  EMPLOYER_TYPE,
  EDITABLE_EMAIL_LOGIN_TYPE,
  NOT_EDITABLE_EMAIL_LOGIN_TYPE,
  AUTH_PROVIDER_TYPES_EMAIL_ADDRESS,
  AUTH_PROVIDER_TYPES_PHONE_NUMBER,
  CPF_MASK_LENGTH,
} from 'util/EvomConstants';
import ptBrLocale from 'date-fns/locale/pt-BR';

export default {
  formatDecimal(value) {
    return currencyFormatter.format(value, {
      symbol: '',
      decimal: ',',
      thousand: '.',
      precision: 2,
      format: '%v',
    });
  },

  formatDecimalInCents(valueInCents) {
    return this.formatDecimal(valueInCents / 100.00);
  },

  parseStringTwoDecimalsCurrencyToValueInCents(currencyFormattedStrValue) {
    if (!currencyFormattedStrValue) {
      return currencyFormattedStrValue;
    }
    let cleanStr = currencyFormattedStrValue.replace(/[,\\.]/g, '');
    let valueInCents = Number(cleanStr);
    return valueInCents;
  },

  clearCurrencyInputValue(inputValue, decimalSeparator=',') {
    if (!inputValue) {
      return inputValue;
    }
    const trimmedInputValue = inputValue.replace('R$ ', '');
    const normalizedInputValue = trimmedInputValue.includes(decimalSeparator) ? trimmedInputValue : `${trimmedInputValue},00`
    const inputValueInCents = this.parseStringTwoDecimalsCurrencyToValueInCents(normalizedInputValue);
    return inputValueInCents;
  },

  clearPercentageInputValue(inputValue, parseToFloat=false, fixedLength=4) {
    const parseFun = parseToFloat
      ? str => {
          // divisao pode gerar imprecisao na conversao do numero.
          const unfixedFloat = parseFloat(str.replace('%', '')) / 100.00;
          // usa toFixed para eliminar imprecisao e converte novamente para 'number'
          const fixedFloat = parseFloat(unfixedFloat.toFixed(fixedLength))
          return fixedFloat;
        }
      : str => parseInt(str.replace('%', '')) / 100;

    return inputValue
      ? parseFun(inputValue)
      : inputValue;
  },

  percentageMultiplierToIntegerPercentage(decimalPercentage) {
    return decimalPercentage && parseFloat(decimalPercentage) * 100;
  },

  /**
   * Converte um float ou uma string que pode ser convertida para float para uma string no formato decimal (p. exemplo,
   * 0.12 -> '12%', ou '0.5423' -> '54.23%').
   *
   * @param {string | number} decimalPercentage A porcentagem em ponto flutuante ou em uma string numerica.
   * @param {number} decimalScale Um numero inteiro que diz o numero de casas depois da virgula. Padrao eh 0.
   * @param {boolean} noSign Booleano que indica se o simbolo de '%' deve ser excluido. Padrao eh false.
   * @returns {string} O numero decimal formatado em porcentagem
   */
  convertDecimalToPercentage(decimalPercentage, decimalScale=0, noSign=false) {
    return decimalPercentage
      && `${this.percentageMultiplierToIntegerPercentage(decimalPercentage).toFixed(decimalScale)}${noSign ? '' : '%'}`;
  },

  formatDateDDMMYYYYHHMM(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }
    const formatted = moment(datetimeToFormat).format("DD/MM/YYYY HH:mm");
    return formatted;
  },

  formatDateDDMMYYYYHHMMSS(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }
    const formatted = moment(datetimeToFormat).format("DD/MM/YYYY HH:mm:ss");
    return formatted;
  },

  formatDateDDMMYYYY(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }
    const formatted = moment(datetimeToFormat).format("DD/MM/YYYY");
    return formatted;
  },

  formatDateDDMMYYYYToYYYYMMDD(serverDatetimeString) {
    if (!serverDatetimeString) {
      return serverDatetimeString;
    }
    const formatted = moment(serverDatetimeString, 'DD/MM/YYYY').format("YYYY-MM-DD")
    return moment(formatted, 'YYYY-MM-DD');
  },

  convertServerDatetimeStringToMoment(serverDatetimeString) {
    return moment(serverDatetimeString, 'YYYY-MM-DDTHH:mm:ssZ');
  },
  
  convertDatetimeStringToMoment(datetimeString) {
    return moment(datetimeString, 'DD-MM-YYYYTHH:mm:ssZ');
  },

  /**
   * retorna um objeto moment caso datetimeString nao seja nulo ou undefined,
   * retorna null caso contrario.
   *
   * @param {string} datetimeString string de data no formato 'DD-MM-YYYYTHH:mm:ssZ'
   * @returns {moment | null} um objeto moment ou null
   */
  nullableConvertDatetimeStringToMoment(datetimeString) {
    return datetimeString !== null && datetimeString !== undefined 
            ? moment(datetimeString)
            : null
    ;
  },

  isMomentValid(datetimeString) {
    if (!datetimeString) {
      return true;
    }
    const datetimeMoment = moment(datetimeString, 'DD/MM/YYYY')
    return datetimeMoment.isValid() && !datetimeMoment.isAfter();
  },

  isUuidValid(stringUUID) {
    return UUID_REGEX_VALIDATION.test(stringUUID);
  },

  formatUuid(uuid) {
    if (!uuid || uuid.length <= 6 || !this.isUuidValid(uuid)) {
      return uuid;
    }
    let formattedUuid = uuid.substr(0, 3);
    formattedUuid += uuid.substr(uuid.length - 3);
    return formattedUuid.toUpperCase();
  },

  formatTransportModal(value) {
    switch (value) {
      case 'URBAN_BUS':
        return 'Ônibus Urbano';
      case 'ROAD_BUS':
        return 'Ônibus Rodoviário';
      case 'METRO':
        return 'Metrô/Ferroviário';
    }
  },

  formatToReal(value) {
    return value ? `R$ ${value}` : '-';
  },

  formatCentsToReal(value) {
    return this.formatToReal(this.formatDecimalInCents(value));
  },

  formatCentsToRealWithSignal(value) {
    if (value >= 0) return `+ ${this.formatToReal(this.formatDecimalInCents(Math.abs(value)))}`;
    else return `- ${this.formatToReal(this.formatDecimalInCents(Math.abs(value)))}`;
  },

  clearPhoneNumberKeepingPlusSign(phone) {
    if (!phone) return phone;
    else return `+${this.clearPhoneNumber(phone)}`;
  },

  clearPhoneNumber(phone) {
    if (!phone) {
      return '';
    }
    return phone.replace(/[^\d]/g, '');
  },

  formatPhoneNumber(phone) {
    const rawPhoneNumber = this.clearPhoneNumber(phone);

    // pattern: +dd (dd) ddddd-dddd | full mobile number
    if (rawPhoneNumber.length === 13) {
      const countryCode = rawPhoneNumber.substring(0, 2);
      const areaCode = rawPhoneNumber.substring(2, 4);
      const firstNumberBlock = rawPhoneNumber.substring(4, 9);
      const secondNumberBlock = rawPhoneNumber.substring(9, 13);

      return "+" + countryCode + " (" + areaCode + ") " + firstNumberBlock + "-" + secondNumberBlock;
    }

    // pattern: +dd (dd) dddd-dddd | full fixed number
    if (rawPhoneNumber.length === 12) {
      const countryCode = rawPhoneNumber.substring(0, 2);
      const areaCode = rawPhoneNumber.substring(2, 4);
      const firstNumberBlock = rawPhoneNumber.substring(4, 8);
      const secondNumberBlock = rawPhoneNumber.substring(8, 12);

      return "+" + countryCode + " (" + areaCode + ") " + firstNumberBlock + "-" + secondNumberBlock;
    }

    // pattern: ddddd-dddd | partial mobile number
    if (rawPhoneNumber.length === 9) {
      const firstNumberBlock = rawPhoneNumber.substring(0, 5);
      const secondNumberBlock = rawPhoneNumber.substring(5, 9);
      return firstNumberBlock + "-" + secondNumberBlock;
    }

    // pattern: dddd-dddd | partial fixed number
    if (rawPhoneNumber.length === 8) {
      const firstNumberBlock = rawPhoneNumber.substring(0, 4);
      const secondNumberBlock = rawPhoneNumber.substring(4, 8);
      return firstNumberBlock + "-" + secondNumberBlock;
    }

    return rawPhoneNumber;
  },

  formatDocument(document) {
    if (!document || document.length !== 11) {
      return document;
    }
    let dot = ".";
    let formattedDocument = document.substr(0, 3) + dot +
      document.substr(3, 3) + dot +
      document.substr(6, 3) + "-" +
      document.substr(document.length - 2);
    document = formattedDocument;
    return document;
  },

  formatCnpj(cnpj) {
    const cleanCnpj = this.clearCnpjTxt(cnpj);

    if (!cleanCnpj || cleanCnpj.length !== 14) {
      return cnpj;
    }

    return (
      cleanCnpj.substr(0, 2) + '.' +
      cleanCnpj.substr(2, 3) + '.' +
      cleanCnpj.substr(5, 3) + '/' +
      cleanCnpj.substr(8, 4) + '-' +
      cleanCnpj.substr(12, 2)
    );
  },

  clearCpfTxt(cpf) {
    if (!cpf) {
      return cpf;
    }
    return cpf.replace(/\./g, '').replace(/ /g, '').replace(/-/g, '').replace(/_/g, '');
  },

  clearCnpjTxt(cnpj) {
    if (!cnpj) {
      return cnpj;
    }
    return cnpj.replace(/\D/g, '');
  },

  /**
   * Checa se uma string tem no maximo o tamanho da mascara um CPF (formato da mascara: XXX.XXX.XXX-XX)
   *
   * @param {string} cpf a string candidata a CPF
   * @returns {boolean} booleano que diz se o argumento tem no maximo o tamanho da mascara um CPF
   */
  lessThanCpfMaskLength(cpf) { return (cpf?.length ?? 0) <=  CPF_MASK_LENGTH },

  lessThanCpfLength(cpf) { return (cpf?.length ?? 0) <=  11 },

  formatEvomAccountStatus(value) {
    switch (value) {
      case 'ENABLED':
        return 'Ativo';
      case 'DISABLED':
        return 'Inativo';
      default:
        return value;
    }
  },

  formatWalletStatus(value) {
    switch (value) {
      case 'ENABLED':
        return 'Ativo';
      case 'DISABLED':
        return 'Inativo';
      default:
        return value;
    }
  },

  formatEmployeeStatus(value) {
    switch (value) {
      case 'REGULAR':
        return 'Ativo';
      case 'DISCHARGED':
        return 'Desligado';
      default:
        return value;
    }
  },

  formatWalletType(value) {
    switch (value) {
      case "REGULAR":
        return "Comum";
      case "WORKER":
        return "Vale Transporte";
      case "STUDENT":
        return "Estudante";
      case "STUDENT_FREE":
        return "Estudante Passe Livre";
      case "ELDERLY":
        return "Idoso";
      case "SPECIAL":
        return "Especial";
      case "GENERIC_1":
        return "Carteira genérica #1";
      case "GENERIC_2":
        return "Carteira genérica #2";
      case "GENERIC_3":
        return "Carteira genérica #3";
      case "GENERIC_4":
        return "Carteira genérica #4";
      case "GENERIC_5":
        return "Carteira genérica #5";
      case "GENERIC_6":
        return "Carteira genérica #6";
      case "GENERIC_7":
        return "Carteira genérica #7";
      case "GENERIC_8":
        return "Carteira genérica #8";
      case "GENERIC_9":
        return "Carteira genérica #9";
      case "GENERIC_10":
        return "Carteira genérica #10";
      default:
        return value;
    }
  },

  shortenWalletType(value) {
    switch (value) {
      case 'Comum':
        return 'Comum';
      case 'Vale Transporte':
        return 'VT';
      case 'Estudante':
        return 'Estudante';
      case 'Estudante Passe Livre':
        return 'EPL';
      case 'Idoso':
        return 'Idoso';
      case 'Especial':
        return 'Especial';
      default:
        return value;
    }
  },

  formatCompanyWalletType(value) {
    switch (value) {
      case 'REGULAR':
        return 'Comum';
      default:
        return value;
    }
  },

  formatInvoiceCreditReleaseStatus(value) {
    switch (value) {
      case 'PENDING':
        return 'Pendente';
      case 'SETTLED':
        return 'Liberado';
      case 'CANCELED':
        return 'Cancelado';
      default:
        return value;
    }
  },

  formatInvoicePaymentStatus(paymentStatus) {
    switch (paymentStatus) {
      case 'PENDING':
        return 'Pendente';
      case 'PAID':
        return 'Pago';
      case 'CANCELED':
        return 'Cancelado';
      default:
        return paymentStatus;
    }
  },

  formatInvoiceReceiptStatus(receiptStatus) {
    switch (receiptStatus) {
      case 'PENDING':
        return 'Pendente';
      case 'APPROVED':
        return 'Aprovado';
      case 'REFUSED':
        return 'Reprovado';
      case 'NOT_APPLICABLE':
        return 'Não se Aplica';
      case 'WAITING_EVALUATION':
        return 'Em análise';
      default:
        return receiptStatus;
    }
  },

  resolveInvoicePaymentStatusColor(paymentStatus) {
    switch (paymentStatus) {
      case 'PENDING':
        return 'orange';
      case 'PAID':
        return 'green';
      case 'CANCELED':
        return 'red';
      default:
        return paymentStatus;
    }
  },

  resolveInvoiceReceiptStatusColor(receiptStatus) {
    switch (receiptStatus) {
      case 'APPROVED':
        return 'green';
      case 'REFUSED':
        return 'red';
      case 'PENDING':
        return 'orange';
      case 'WAITING_EVALUATION':
        return 'blue';
      case 'NOT_APPLICABLE':
        return 'grey';
      default:
        return receiptStatus;
    }
  },

  formatTopUpInvoiceType(topUpInvoiceType) {
    switch (topUpInvoiceType) {
      case 'REGULAR':
        return 'Colaborador';
      case 'COMPANY_BALANCE':
        return 'Saldo Empregador';
      default:
        return topUpInvoiceType;
    }
  },

  formatAgencyTopUpInvoiceType(topUpInvoiceType) {
    switch (topUpInvoiceType) {
      case 'COMPANY_BALANCE':
        return 'Saldo Agência';
      default:
        return this.formatTopUpInvoiceType(topUpInvoiceType);
    }
  },

  resolveInvoiceStatusColor(value) {
    switch (value) {
      case 'PENDING':
        return 'orange';
      case 'SETTLED':
        return 'green';
      case 'CANCELED':
        return 'red';
      default:
        return value;
    }
  },

  formatTopUpInvoiceSettlementType(topUpInvoiceSettlementType) {
    switch (topUpInvoiceSettlementType) {
      case 'MANUAL_CASH':
        return 'Dinheiro';
      case 'GN_BANKING_BILLET':
      case 'MANUAL_BANKING_BILLET':
        return 'Boleto';
      case 'GN_PIX':
        return 'PIX';
      case 'MANUAL_TRANSFER':
        return 'Transferência';
      case 'MANUAL_CASH_DEPOSIT':
        return 'Depósito';
      case 'MANUAL_PIX':
        return 'PIX';
      case 'AUTO_COMPANY_BALANCE':
        return 'Saldo empregador';
      case 'AUTO_CREDIT_CARD':
        return 'Cartão de crédito';
      default:
        return topUpInvoiceSettlementType;
    }
  },

  formatTransactionStatus(status) {
    switch (status) {
      case 'CREATED':
        return 'Criada';
      case 'INVALID':
        return 'Inválida';
      case 'AUTHORIZED':
        return 'Autorizada';
      case 'UNAUTHORIZED':
        return 'Negada';
      case 'FAILED':
        return 'Falha';
      case 'CANCELED':
        return 'Cancelada';
      case 'FULL_REFUND':
        return 'Reembolso total';
      case 'PARTIAL_REFUND':
        return 'Reembolso parcial';
      case 'UNKNOWN_ERROR':
        return 'Erro Desconhecido';
      default:
        return status;
    }
  },

  getTransactionStatusBadge(status) {
    switch (status) {
      case 'AUTHORIZED':
        return 'badge badge-success';
      case 'CREATED':
        return 'badge badge-warning';
      case 'INVALID':
      case 'UNAUTHORIZED':
      case 'FAILED':
      case 'CANCELED':
      case 'FULL_REFUND':
      case 'PARTIAL_REFUND':
      case 'UNKNOWN_ERROR':
        return 'badge badge-danger';
      default:
        return 'badge badge-light';
    }
  },

  getPOSTransactionStatusBadge(status) {
    switch (status) {
      case 'AUTHORIZED':
        return 'badge badge-success';
      case 'PENDING':
        return 'badge badge-warning';
      case 'ERROR':
        return 'badge badge-danger';
      default:
        return 'badge badge-light';
    }
  },

  getCompanyTransactionStatusBadge(status) {
    switch (status) {
      case 'AUTHORIZED':
        return 'badge badge-success';
      case 'PENDING':
        return 'badge badge-warning';
      case 'ERROR':
        return 'badge badge-danger';
      default:
        return 'badge badge-light';
    }
  },

  formatTransactionType(value) {
    switch (value) {
      case 'TOP_UP':
        return 'Recarga';
      case 'GET_ON_TRANSPORT':
        return 'Pagamento de transporte';
      case 'TRANSFER_INCOME':
        return 'Transferência de Saldo (entrada)';
      case 'TRANSFER_OUTCOME':
        return 'Transferência de Saldo (saída)';
      case 'ROLLBACK':
        return 'Estorno';
      case 'TOP_UP_CASHBACK':
        return 'Cashback';
      case 'ROAD_TICKET_SALE_PAYMENT':
        return 'Compra de passagem';
      case 'TOP_UP_EXPIRATION':
        return 'Expiração de crédito';
      default:
        return value;
    }
  },

  formatTransactionPaymentGatewayMne(value) {
    switch (value) {
      case 'EVOM_PASS':
        return 'EvomPass';
      case 'VALE_FACIL':
        return 'Vale Fácil';
      default:
        return value;
    }
  },

  getAuthUserAuthority(authUser) {
    if (!authUser) return authUser;
    if (authUser.authorities.includes(ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN)) return ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN;
    if (authUser.authorities.includes(ROLE_AUTHORITIES.ROLE_COMPANY_USER)) return ROLE_AUTHORITIES.ROLE_COMPANY_USER;
    if (authUser.authorities.includes(ROLE_AUTHORITIES.ROLE_EMPLOYER_USER)) return ROLE_AUTHORITIES.ROLE_EMPLOYER_USER;
    return null;
  },

  isUserAdmin(perms) {
    return (perms ?? []).includes('PERM_BO_ADMIN');
  },

  formatUserStatus(status) {
    switch (status) {
      case true:  return 'Ativo';
      case false: return 'Inativo';
      default:    return status;
    }
  },

  getUserStatusBadge(status) {
    switch (status) {
      case true:  return 'badge badge-success';
      case false: return 'badge badge-danger';
      default:    return 'badge badge-light';
    }
  },

  formatLoginType(loginType) {
    switch (loginType) {
      case 'apple.com':
        return 'Apple';
      case 'password':
      case 'FIREBASE_GMAIL':
        return 'E-mail e senha';
      case 'google.com':
        return 'Gmail';
      case 'FIREBASE_PHONE':
      case 'phone':
        return 'Celular';
      default:
        return loginType;
    }
  },

  formatAuthenticationProvider(authProv) {
    switch (authProv) {
      case 'APPLE':
        return 'Apple';
      case 'GOOGLE':
        return 'Google';
      case 'PHONE':
        return 'Celular';
      case 'EMAIL':
      case 'PASSWORD':
        return 'E-mail/Senha';
      default:
        return authProv;
    }
  },

  formatPaymentMethodType(paymentMethodType, topUpInvoiceSettlementType) {
    switch (paymentMethodType) {
      case 'CREDIT_CARD':
        return 'Cartão de Crédito';
      case 'PIX':
        return 'PIX'
      case 'EVOM_BALANCE':
        return 'Saldo da Conta'
      case 'TOP_UP_INVOICE': {
        switch (topUpInvoiceSettlementType) {
          case 'MANUAL_CASH':
            return 'Fatura - Dinheiro';
          case 'GN_BANKING_BILLET':
          case 'MANUAL_BANKING_BILLET':
            return 'Fatura - Boleto';
          case 'GN_PIX':
          case 'MANUAL_PIX':
            return 'Fatura - PIX';
          case 'MANUAL_TRANSFER':
            return 'Fatura - Transferência';
          case 'MANUAL_CASH_DEPOSIT':
            return 'Fatura - Depósito bancário';
          default:
            return 'Fatura';
        }
      }
      case 'POS_BANK_SLIP':
        return 'PDV - Boleto';
      case 'POS_MONEY':
        return 'PDV - Dinheiro';
      case 'POS_BANK_TRANSFER':
        return 'PDV - Transferência';
      case 'POS_MIGRATION':
        return 'PDV - Migração';
      case 'OTHER':
        return 'PDV - Outros';
      case 'CASHBACK':
        return 'Cashback';
      default:
        return paymentMethodType;
    }
  },

  formatCreditRechargeStatus(value) {
    switch (value) {
      case 'CREATED':
        return 'Em Andamento';
      case 'INVALID':
        return 'Inválida';
      case 'AUTHORIZED':
        return 'Pago';
      case 'PARTIAL_REFUND':
        return 'Reembolso Parcial';
      case 'FULL_REFUND':
        return 'Reembolso Integral';
      case 'UNAUTHORIZED':
        return 'Negada';
      case 'FAILED':
        return 'Falha';
      case 'CANCELED':
        return 'Cancelado';
      case 'UNKNOWN_ERROR':
        return 'Erro Desconhecido';
      default:
        return value;
    }
  },

  formatWalletApplicationStatus(value) {
    switch (value) {
      case 'CREATED':
        return 'Em Andamento';
      case 'DRAFT':
        return 'Rascunho';
      case 'SUBMITTED':
        return 'Aguardando avaliação';
      case 'REQUESTED_REVIEW':
        return 'Solicitação de revisão';
      case 'APPROVED':
        return 'Aprovada';
      case 'REJECTED':
        return 'Reprovada';
      default:
        return value;
    }
  },

  formatPOSTransactionStatus(value) {
    switch (value) {
      case 'PENDING':
        return 'Pendente';
      case 'AUTHORIZED':
        return 'Pago';
      case 'ERROR':
        return 'Erro';
      default:
        return value;
    }
  },

  convertWalletParameterType(type) {
    switch (type) {
      case 'FULL_NAME':
      case 'fullName':
        return 'Nome completo';
      case 'BIRTH_DATE':
      case 'birthDate':
        return 'Data de nascimento';
      case 'EMAIL':
      case 'email':
        return 'E-mail';
      case 'DRIVERS_LICENSE':
      case 'driversLicense':
        return 'CNH';
      case 'NATIONAL_ID':
      case 'nationalId':
        return 'RG';
      case 'DOCUMENT_NUMBER':
      case 'documentNumber':
        return 'CPF';
      case 'ADDRESS':
      case 'address':
        return 'Endereço';
      case 'ENROLLMENT_ID':
      case 'enrollmentId':
        return 'Número de matrícula';
      case 'MEDICAL_REPORT':
      case 'medicalReport':
        return 'Laudo médico';
      case 'note':
        return 'Observação';
      case 'deniedReason':
        return 'Justificativa';
      default:
        return type;
    }
  },

  formatIdentificationMethodType(identificationMethodType) {
    switch (identificationMethodType) {
      case 'QRCODE_MOBILE':
        return 'QR Code Aplicativo';
      case 'QRCODE_CARD':
        return 'QR Code Cartão';
      case 'QRCODE_BRACELET':
        return 'QR Code Pulseira';
      case 'QRCODE_PAPER':
        return 'QR Code Impresso';
      case 'NFC_MOBILE':
        return 'NFC Aplicativo';
      case 'NFC_CARD':
        return 'NFC Cartão';
      case 'NFC_BRACELET':
        return 'NFC Pulseira';
      case 'VOUCHER':
        return 'Voucher';
      default:
        return identificationMethodType;
    }
  },

  shortenIdentificationMethodType(identificationMethodType) {
    switch (identificationMethodType) {
      case 'QR Code Aplicativo':
        return 'QRA';
      case 'QR Code Cartão':
        return 'QRC';
      case 'QR Code Pulseira':
        return 'QRP';
      case 'QR Code Impresso':
        return 'QRI';
      case 'NFC Aplicativo':
        return 'NFCA';
      case 'NFC Cartão':
        return 'NFCC';
      case 'NFC Pulseira':
        return 'NFCP';
      default:
        return identificationMethodType;
    }
  },

  mergeArraysByProperty(target, source, prop) {
    source.forEach(sourceElement => {
      let targetElement = target.find(targetElement => {
        return sourceElement[prop] === targetElement[prop];
      })
      targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
    })
  },

  isObjectEmpty(obj) {
    return !obj || Object.keys(obj).length === 0;
  },

  formatPaymentItem(paymentItemType) {
    switch (paymentItemType) {
      case 'OTHER':
        return 'PDV - Outros';
      case 'EVOM_BALANCE':
        return 'Carteira Evom';
      case 'POS_BANK_TRANSFER':
        return 'PDV - Transferência';
      case 'CREDIT_CARD':
        return 'Cartão de crédito';
      case 'PIX':
        return 'PIX';
      case 'POS_BANK_SLIP':
        return 'PDV - Boleto';
      case 'TOP_UP_INVOICE':
        return 'Fatura';
      case 'POS_MONEY':
        return 'PDV - Dinheiro';
      case 'POS_MIGRATION':
        return 'PDV - Migração';
      default:
        return paymentItemType;
    }
  },

  calculateDiscountValue(totalValue, discountPolicy) {
    if (!totalValue || !discountPolicy) return 0;
    const discountPercentage = discountPolicy?.discountPercentage ?? 0;
    return Math.trunc(discountPercentage * totalValue);
  },

  calculateNetValue(totalValue, discountPolicy) {
    if (!totalValue || !discountPolicy) return 0;
    const discountValue = this.calculateDiscountValue(totalValue, discountPolicy);
    return Math.trunc(totalValue - discountValue);
  },

  isEmailLoginType(loginType) {
    return LOGIN_TYPE_EMAIL.includes(loginType);
  },

  isPhoneLoginType(loginType) {
    return LOGIN_TYPE_PHONE.includes(loginType);
  },

  isEditableEmailLoginType(loginType){
    return EDITABLE_EMAIL_LOGIN_TYPE.includes(loginType);
  },

  isNotEditableEmailLoginType(loginType){
    return NOT_EDITABLE_EMAIL_LOGIN_TYPE.includes(loginType);
  },

  userHasAnyEmailAuthProvider(userAuthProviders) {
    return AUTH_PROVIDER_TYPES_EMAIL_ADDRESS.some(el => userAuthProviders?.includes(el) ?? false);
  },

  userHasAnyPhoneAuthProvider(userAuthProviders) {
    return AUTH_PROVIDER_TYPES_PHONE_NUMBER.some(el => userAuthProviders?.includes(el) ?? false);
  },

  formatCompanyTransactionType(companyTransactionType, translation={}) {
    switch (companyTransactionType) {
      case 'TRANSFER':
        return translation[companyTransactionType] ?? 'Transferência';
      case 'VOID':
        return translation[companyTransactionType] ?? 'Cancelamento';
      case 'INVOICE':
        return translation[companyTransactionType] ?? 'Fatura - saldo empregador';
      case 'POS_TRANSACTION':
        return translation[companyTransactionType] ?? 'Transação da Agência (PDV)';
      default:
        return companyTransactionType;
    }
  },

  formatCompanyTransactionStatus(companyTransactionStatus) {
    switch (companyTransactionStatus) {
      case 'AUTHORIZED':
        return 'Autorizado';
      case 'PENDING':
        return 'Pendente';
      case 'ERROR':
        return 'Erro';
      default:
        return companyTransactionStatus;
    }
  },

  formatAgencyTransactionType(agencyTransactionType) {
    switch (agencyTransactionType) {
      case 'INVOICE':
        return 'Fatura - saldo agência';
      case 'POS_TRANSACTION':
        return 'Transação da Agência';
      default:
        return this.formatCompanyTransactionType(agencyTransactionType);
    }
  },

  formatTransactionDirection(transactionDirection) {
    switch (transactionDirection) {
      case 'CREDIT':
        return 'Crédito';
      case 'DEBIT':
        return 'Débito';
      default:
        return transactionDirection;
    }
  },

  getWalletTransactionDirection(walletTransaction) {
    switch (walletTransaction.type) {
      case 'TOP_UP':
      case 'TRANSFER_INCOME':
      case 'TOP_UP_CASHBACK':
        return 'CREDIT';

      case 'GET_ON_TRANSPORT':
      case 'TRANSFER_OUTCOME':
      case 'ROAD_TICKET_SALE_PAYMENT':
      case 'TOP_UP_EXPIRATION':
        return 'DEBIT';

      case 'ROLLBACK':
        return walletTransaction.paymentMethodType === 'EVOM_BALANCE' ? 'CREDIT' : 'DEBIT';

      default:
        return '';
    }
  },

  formatReportExecutionStatus(reportExecutionStatus) {
    switch (reportExecutionStatus) {
      case 'CREATED':
        return 'Criado';
      case 'FINISHED':
        return 'Gerado';
      case 'EXECUTING':
        return 'Gerando';
      case 'ERROR':
        return 'Erro';
      default:
        return reportExecutionStatus;
    }
  },

  getReportExecutionStatusBadge(reportExecutionStatus) {
    switch (reportExecutionStatus) {
      case 'CREATED':
        return '#7e7e7e';
      case 'FINISHED':
        return '#28a745';
      case 'EXECUTING':
        return '#ffc105';
      case 'ERROR':
        return '#dc3545';
      default:
        return '#7e7e7e';
    }
  },

  formatReportExecutionLineLevel(reportExecutionStatus) {
    switch (reportExecutionStatus) {
      case 'INFO':
        return 'Informativo';
      case 'WARNING':
        return 'Alerta';
      case 'ERROR':
        return 'Perigo';
      case 'CRITICAL':
        return 'Grave';
      default:
        return reportExecutionStatus;
    }
  },

  formatPromotionCashInType(promotionCashInType) {
    switch (promotionCashInType) {
      case 'DISCOUNT':
        return 'Desconto';
      case 'CASHBACK':
        return 'Cashback';
      default:
        return promotionCashInType;
    }
  },

  formatPromotionCashInValueType(promotionCashInValueType) {
    switch (promotionCashInValueType) {
      case 'FIXED':
        return 'Fixo';
      case 'PERCENTAGE':
        return 'Porcentagem';
      default:
        return promotionCashInValueType;
    }
  },

  formatEmployerType(employerType) {
    switch (employerType) {
      case EMPLOYER_TYPE.INDIVIDUAL: return 'Pessoa física';
      case EMPLOYER_TYPE.COMPANY:    return 'Pessoa jurídica';
      default:                       return employerType;
    }
  },

  getReportExecutionLineLevelBadge(reportExecutionStatus) {
    switch (reportExecutionStatus) {
      case 'INFO':
        return '#28a745';
      case 'WARNING':
        return '#ffc105';
      case 'ERROR':
        return '#ff7300';
      case 'CRITICAL':
        return '#dc3545';
      default:
        return '#555';
    }
  },

  flattenObject(toBeFlattenedObject) {
    const flattenedObject = {};
    const toBeFlattenedObjectProperties = Object.keys(toBeFlattenedObject);

    for (const property of toBeFlattenedObjectProperties) {
      if (
        toBeFlattenedObject[property] !== null &&
        typeof toBeFlattenedObject[property] === 'object'
      ) {
        const flattenedSubObject = this.flattenObject(toBeFlattenedObject[property]);
        const flattenedSubObjectProperties = Object.keys(flattenedSubObject);

        for (const flattenedSubObjectProperty of flattenedSubObjectProperties) {
          flattenedObject[`${property}.${flattenedSubObjectProperty}`] = flattenedSubObject[flattenedSubObjectProperty];
        }
      } else {
        flattenedObject[property] = toBeFlattenedObject[property];
      }
    }

    return flattenedObject;
  },

  getDurationFromNow(ISOStringDate) {
    if (!ISOStringDate) return null;
    return intervalToDuration({ start: new Date(ISOStringDate), end: Date.now() });
  },

  getDurationBetween(StartISOStringDate, EndISOStringDate) {
    if (!StartISOStringDate || !EndISOStringDate) return null;
    return intervalToDuration({ start: new Date(StartISOStringDate), end: new Date(EndISOStringDate) });
  },

  getFormattedDuration(duration) {
    if (!duration) return null;
    return formatDuration(duration, {
      format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
      locale: ptBrLocale,
    });
  },

  showEmployeeBalance(authUser, activeCompanyData) {
    const isPmgargAdmin = authUser?.authorities?.includes(ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN) === true;
    const isHideEmployeeBalance = activeCompanyData?.hideEmployeeBalance === true;
    return isPmgargAdmin || !isHideEmployeeBalance;
  },

  translateEntity(entityName) {
    const entityNameAndTranslation = {
      Wallet: 'Carteira',
      wallet: 'Carteira',
      CompanyWallet: 'Carteira',
      companyWallet: 'Carteira',
      WalletTransaction: 'Transação',
      walletTransaction: 'Transação',
      Company: 'Empregador',
      company: 'empregador',
      CompanyTransaction: 'Transação do empregador',
      companyTransaction: 'Transação do empregador',
      EvomAccount: 'Conta',
      evomAccount: 'Conta',
      POSOperator: 'Usuário PDV',
      posOperator: 'Usuário PDV',
      TopUpInvoice: 'Fatura',
      topUpInvoice: 'Fatura',
    };

    return entityNameAndTranslation[entityName] ?? entityName;
  },

  isSystemUnavailable(error) {
    return (error.response === undefined) || (error.response.status === 503)
  },

  formatReceiptDeniedReason(deniedReason) {
    switch (deniedReason) {
      case 'WRONG_ATTACHMENT':
        return 'Anexo incorreto';
      
      case 'UNREADABLE':
        return 'Ilegível';
      
      case 'OVERDUE':
        return 'Vencido';
      
      case 'CANCELED':
        return 'Cancelado';
      
      case 'CANCELLED_BY_SUBMITTER':
        return 'Cancelado pelo solicitante';
      
      case 'OTHER':
        return 'Outros';
    
      default:
        return deniedReason;
    }
  },

  formatApplicationDeniedReason(deniedReason) {
    switch (deniedReason) {
      case 'FAKE_DOCS':
        return 'Documentos falsos';
      
      case 'MISUSE':
        return 'Uso indevido';
      
      case 'RULE_VIOLATION':
        return 'Violação de regras';
      
      case 'WRONG_ATTACHMENT':
        return 'Anexo errado';
      
      case 'PENDING_DOCS':
        return 'Documentos pendentes';
      
      case 'OTHER':
        return 'Outros';
    
      default:
        return deniedReason;
    }
  },

  formatRechargeBalanceType(recharge) {
    switch (recharge) {
      case 'PRE_PAID':
        return 'Pré-pago';
      case 'POST_PAID':
        return 'Pós-pago';
      default:
        return recharge;
    }
  },

  formatBillingCycleType(cycle) {
    switch (cycle) {
      case 'MANUAL':
        return 'Manual';
      case 'DAILY':
        return 'Diário';
      case 'WEEKLY':
        return 'Semanal';
      case 'MONTHLY':
        return 'Mensal';
      default:
        return cycle;
    }
  },

  formatWeekDays(weekDay, shortName=false) {
    switch (weekDay) {
      case 'SUNDAY':
        return shortName ? 'DOM/FER' : 'Domingo/Feriados'
      case 'MONDAY':
        return shortName ? 'SEG' : 'Segunda-feira'
      case 'TUESDAY':
        return shortName ? 'TER' : 'Terça-feira'
      case 'WEDNESDAY':
        return shortName ? 'QUA' : 'Quarta-feira'
      case 'THURSDAY':
        return shortName ? 'QUI' : 'Quinta-feira'
      case 'FRIDAY':
        return shortName ? 'SEX' : 'Sexta-feira'
      case 'SATURDAY':
        return shortName ? 'SAB' : 'Sábado'
      default:
        break;
    }
  },

  formatDiaOrDias(days) {
    return days === '1' || days === 1 ? 'dia' : 'dias';
  },

  formatDays(days) {
    if (days === undefined || days === null) return '';
    return `${days} ${this.formatDiaOrDias(days)}`;
  },

  isNumber(x) {
    return typeof x === 'number';
  },

  isArray(x) {
    return x instanceof Array;
  },

  arrayDeepEquals(x, y) {
    const xSet = new Set(x);
    const ySet = new Set(y);

    // pela igualdade de conjuntos
    return xSet.isSubsetOf(ySet) && ySet.isSubsetOf(xSet);
  },

  /**
   * Formata uma lista de objetos cujo um dos atributos é um label, em um
   * componente que exibe esses labels um sobre o outro, separados por vírgula.
   *
   * @param {Array<Object>} arr - O array de objetos.
   * @param {string} label - A chave do objeto contendo o rotulo do objeto. O
   * valor padrão para esse campo é 'label'.
   * @returns {JSX} um componente que contem uma sequencia de tags <p>, cada uma
   * delas contendo os rótulos de cada objeto de arr.
   */
  formatLabeledList(arr, label='label') {
    return (
      <>
        {
          arr?.map((item, idx) =>
            <p key={idx}>
              {`${item[label]}${idx !== arr.length - 1 ? ',' : ''}`}
            </p>
          ) ?? ''
        }
      </>
    );
  },

  /**
   * Formata uma lista de objetos cujo um dos atributos é um label, em um
   * componente que exibe esses labels um sobre o outro, separados por vírgula.
   *
   * @param {Array<Object>} arr - O array de objetos.
   * @param {string} label - A chave do objeto contendo o rotulo do objeto. O
   * valor padrão para esse campo é 'label'.
   * @returns {JSX} um componente que contem uma sequencia de tags <p>, cada uma
   * delas contendo os rótulos de cada objeto de arr.
   */
  formatLabeledList(arr, label='label') {
    return (
      <>
        {
          arr?.map((item, idx) =>
            <p key={idx}>
              {`${item[label]}${idx !== arr.length - 1 ? ',' : ''}`}
            </p>
          ) ?? ''
        }
      </>
    );
  },

  /**
   * @deprecated Usar makeRedirectUrl de PageRedirectUtils
   *
   * Cria uma URL com filtros na forma de query parameters (ex: /baseUrl?key1=value1&key2=value2&...)
   * para realizar o redirecionamento para uma pagina.
   *
   * @param {string} pageBaseUrl a URL base da pagina para realizar o redirecionamento (primeiro char eh '/' !!!)
   * @param {Object<string, Array<string>>} filters um dicionario cujas chaves sao os nomes dos filtros de URL
   * para a pagina de destino (ex: {key1: ..., key2: ...} corresponde a /...?key1=...&key2=...) e os valores dessas
   * chaves sao listas contendo os filtros ativos para a sua chave 
   * (ex: { key1: ['value11', 'value12'], key2: ['value21', 'value22'] }
   * corresponde a /...?key1=value11&key1=value12&key2=value21&key2=value22)
   */
  makeRedirectUrl(pageBaseUrl, filters) {
    return Object.entries(filters)
      .reduce((acc, [k, v]) => `${acc}${v.reduce((innerAcc, i) => `${innerAcc}&${k}=${i}`, '')}`, `${pageBaseUrl}?`)
  }, 
};
